<template>
  <content-layout :breadcrumbs-items="breadcrumbsItems">
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-card :max-width="1123" class="my-6" outlined rounded="lg">
        <v-card-text>
          <v-form
            @submit.prevent="saveAccountSettings"
            v-if="accountCopy"
            :disabled="loading"
          >
            <v-row>
              <v-col :cols="12"
                ><div class="text-h6 font-weight-semibold">
                  פרטים כלליים
                </div></v-col
              >
              <v-col :cols="12">
                <cz-form-field :label="$t('customer.supportNumber')">
                  <cz-input
                    v-model="accountCopy.supportNumber"
                    readonly
                    class="font-weight-semibold"
                  />
                </cz-form-field>
              </v-col>
              <v-col :lg="3" :md="4" :sm="6">
                <cz-form-field :label="$t('auth.companyName')" required>
                  <validation-provider
                    rules="required"
                    :name="$t('auth.companyName')"
                    v-slot="{ errors }"
                  >
                    <cz-input
                      v-model="accountCopy.companyName"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </cz-form-field>
              </v-col>
              <v-col :lg="3" :md="4" :sm="6">
                <cz-form-field :label="$t('customer.businessId')">
                  <cz-input v-model="accountCopy.businessId" readonly />
                </cz-form-field>
              </v-col>
              <v-col :lg="3" :md="4" :sm="6">
                <cz-form-field :label="$t('admin.authorizedDomain')" required>
                  <validation-provider
                    rules="required|domain"
                    :name="$t('admin.authorizedDomain')"
                    v-slot="{ errors }"
                  >
                    <div class="d-flex justify-space-between align-center">
                      <cz-input
                        v-model="accountCopy.authorizedDomain"
                        :error-messages="errors"
                        hide-details
                        readonly
                      />

                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <cz-button
                            :icon-src="mdiInformationOutline"
                            text
                            :icon-size="20"
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                          />
                        </template>
                        <span>{{
                          $t('customer.autorizedDomainChangeHint')
                        }}</span>
                      </v-tooltip>
                    </div>
                  </validation-provider>
                </cz-form-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12">
                <div class="text-h6 font-weight-semibold">איש קשר</div></v-col
              >
              <v-col :lg="3" :md="4" :sm="6">
                <cz-form-field :label="$t('customer.contactPersonName')">
                  <validation-provider
                    :name="$t('customer.contactPersonName')"
                    v-slot="{ errors }"
                    rules="fullName"
                  >
                    <cz-input
                      v-model="accountCopy.contactPersonName"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </cz-form-field>
              </v-col>
              <v-col :lg="3" :md="4" :sm="6">
                <cz-form-field :label="$t('customer.contactPersonEmail')">
                  <validation-provider
                    :name="$t('customer.contactPersonEmail')"
                    v-slot="{ errors }"
                    rules="email"
                  >
                    <cz-input
                      v-model="accountCopy.contactPersonEmail"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </cz-form-field>
              </v-col>
              <v-col :lg="3" :md="4" :sm="6">
                <cz-form-field :label="$t('customer.contactPersonPhone')">
                  <validation-provider
                    :name="$t('customer.contactPersonPhone')"
                    v-slot="{ errors }"
                    rules="phoneNumber"
                  >
                    <cz-input
                      v-model="accountCopy.contactPersonPhone"
                      :error-messages="errors"
                      type="tel"
                    />
                  </validation-provider>
                </cz-form-field>
              </v-col>
            </v-row>
            <div class="d-flex mt-5">
              <cz-button
                :title="$t('common.saveChanges')"
                color="primary"
                type="submit"
                :disabled="!formChanged || invalid"
                :loading="loading"
              />
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </validation-observer>
  </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout.vue';
import { mapFields } from 'vuex-map-fields';
import cloneDeep from 'lodash.clonedeep';
import logo from '@/assets/corz.png';
import isEqual from 'lodash.isequal';
import {
  CzFormField,
  CzInput,
  CzButton,
  CzDropzone,
  CzIcon,
  CzChip
} from '@/components';
import {
  fileReaderPromise,
  ReaderType
} from '@/shared/services/fileReader.service';
import { updateAccount, uploadFiles } from '@/core/api';
import { mdiInformationOutline } from '@mdi/js';
export default {
  name: 'AccountSettings',
  components: {
    ContentLayout,
    CzFormField,
    CzInput,
    CzButton,
    CzChip,
    CzIcon,
    CzDropzone
  },
  computed: {
    ...mapFields('auth', ['account']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.accountSettings'),
          disabled: true,
          exact: true
        }
      ];
    },
    logo() {
      if (this.selectedLogoSrc) {
        return this.selectedLogoSrc;
      } else if (!this.accountCopy.logo) {
        return logo;
      } else {
        return this.accountCopy.logo.url;
      }
    },
    formChanged() {
      return this.selectedLogoFile || !isEqual(this.account, this.accountCopy);
    }
  },
  created() {
    this.accountCopy = cloneDeep(this.account);
  },
  data() {
    return {
      mdiInformationOutline,
      accountCopy: null,
      selectedLogoFile: null,
      selectedLogoSrc: null,
      loading: false
    };
  },
  methods: {
    async saveAccountSettings() {
      try {
        this.loading = true;
        if (this.selectedLogoFile) {
          const [logoFile] = await uploadFiles(
            [this.selectedLogoFile],
            this.account._id
          );

          this.accountCopy.logoFileId = logoFile._id;
        }

        const account = await updateAccount(this.account._id, this.accountCopy);
        this.account = cloneDeep(account);
        this.accountCopy = cloneDeep(account);
        this.selectedLogoFile = null;
      } finally {
        this.loading = false;
      }
    },
    async onLogoChanged(files) {
      this.selectedLogoFile = files[0];
    }
  },
  watch: {
    selectedLogoFile: {
      async handler(value) {
        if (value) {
          this.selectedLogoSrc = await fileReaderPromise(value, ReaderType.URL);
        } else {
          a;
          this.selectedLogoSrc = null;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.account-form {
  width: 400px !important;
  gap: 10px;
}
.dropzone-files {
  max-width: 200px;
}
</style>
